/*! Bootstrap */
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

/*! Variables */
$atom-one-dark-black: #282c34;
$atom-one-dark-gray: #abb2bf;
$atom-one-dark-red: #e06c75;
$atom-one-dark-green: #98c379;
$atom-one-dark-yellow: #e5c07b;
$atom-one-dark-blue: #61afef;
$atom-one-dark-purple: #c678dd;
$atom-one-dark-teal: #56b6c2;
$atom-one-dark-orange: #d19a66;
$atom-one-dark-gray-blue: #5c6370;

$body-bg-dark: $atom-one-dark-black;
// $primary: $atom-one-dark-blue; // Already this
$secondary: $atom-one-dark-gray-blue;
$light: $atom-one-dark-gray;
$dark: $atom-one-dark-black;
$blue: $atom-one-dark-blue;
// $indigo: ; // No one dark indigo
$purple: $atom-one-dark-purple;
// $pink: ; // No one dark pink
$red: $atom-one-dark-red;
$orange: $atom-one-dark-orange;
$yellow: $atom-one-dark-yellow;
$green: $atom-one-dark-green;
$teal: $atom-one-dark-teal;
// $cyan: ; // No one dark cyan, one needs to be found as used lots
// $enable-gradients: true;
// $color-mode-type: media-query;
// $navbar-dark-hover-color: #fff;
// $navbar-dark-color: rgba(#fff, 0.75);
// $carousel-control-opacity: 1;
// $carousel-control-hover-opacity: 0.75;
// $font-family-sans-serif: "Montserrat", system-ui, -apple-system, "Segoe UI",
//   roboto, "Helvetica Neue", arial, "Noto Sans", "Liberation Sans", sans-serif,
//   "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// $input-bg: #fff;

/* Bootstrap (again) */

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";
// @import "../../../node_modules/bootstrap/scss/accordion";
@import "../../../node_modules/bootstrap/scss/alert";
// @import "../../../node_modules/bootstrap/scss/badge";
// @import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/buttons";
// @import "../../../node_modules/bootstrap/scss/card";
// @import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/close";
// @import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/forms";
// @import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/tooltip";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../../node_modules/bootstrap/scss/utilities/api";
