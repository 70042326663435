:root {
  --bs-blue: #61afef;
  --bs-indigo: #6610f2;
  --bs-purple: #c678dd;
  --bs-pink: #d63384;
  --bs-red: #e06c75;
  --bs-orange: #d19a66;
  --bs-yellow: #e5c07b;
  --bs-green: #98c379;
  --bs-teal: #56b6c2;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #61afef;
  --bs-secondary: #5c6370;
  --bs-success: #98c379;
  --bs-info: #0dcaf0;
  --bs-warning: #e5c07b;
  --bs-danger: #e06c75;
  --bs-light: #abb2bf;
  --bs-dark: #282c34;
  --bs-primary-rgb: 97, 175, 239;
  --bs-secondary-rgb: 92, 99, 112;
  --bs-success-rgb: 152, 195, 121;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 229, 192, 123;
  --bs-danger-rgb: 224, 108, 117;
  --bs-light-rgb: 171, 178, 191;
  --bs-dark-rgb: 40, 44, 52;
  --bs-primary-text-emphasis: #274660;
  --bs-secondary-text-emphasis: #25282d;
  --bs-success-text-emphasis: #3d4e30;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #5c4d31;
  --bs-danger-text-emphasis: #5a2b2f;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #dfeffc;
  --bs-secondary-bg-subtle: #dee0e2;
  --bs-success-bg-subtle: #eaf3e4;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #faf2e5;
  --bs-danger-bg-subtle: #f9e2e3;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #c0dff9;
  --bs-secondary-border-subtle: #bec1c6;
  --bs-success-border-subtle: #d6e7c9;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #f5e6ca;
  --bs-danger-border-subtle: #f3c4c8;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #212529bf;
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: #21252980;
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #61afef;
  --bs-link-color-rgb: 97, 175, 239;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #4e8cbf;
  --bs-link-hover-color-rgb: 78, 140, 191;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #faf2e5;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 .5rem 1rem #00000026;
  --bs-box-shadow-sm: 0 .125rem .25rem #00000013;
  --bs-box-shadow-lg: 0 1rem 3rem #0000002d;
  --bs-box-shadow-inset: inset 0 1px 2px #00000013;
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: #61afef40;
  --bs-form-valid-color: #98c379;
  --bs-form-valid-border-color: #98c379;
  --bs-form-invalid-color: #e06c75;
  --bs-form-invalid-border-color: #e06c75;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #282c34;
  --bs-emphasis-color: #fff;
  --bs-secondary-color: #dee2e6bf;
  --bs-secondary-bg: #343a40;
  --bs-tertiary-bg: #2b3035;
  --bs-warning-text-emphasis: #efd9b0;
  --bs-danger-text-emphasis: #eca7ac;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-warning-bg-subtle: #2e2619;
  --bs-danger-bg-subtle: #2d1617;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-warning-border-subtle: #89734a;
  --bs-danger-border-subtle: #864146;
  --bs-dark-border-subtle: #343a40;
  --bs-link-color-rgb: 160, 207, 245;
  --bs-link-hover-color-rgb: 179, 217, 247;
  --bs-border-color: #495057;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img, svg {
  vertical-align: middle;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

button, [type="button"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

summary {
  cursor: pointer;
  display: list-item;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col-12 {
  flex: none;
  width: 100%;
}

@media (width >= 576px) {
  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

:not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #61afef;
  --bs-btn-border-color: #61afef;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #79bbf1;
  --bs-btn-hover-border-color: #71b7f1;
  --bs-btn-focus-shadow-rgb: 82, 149, 203;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #81bff2;
  --bs-btn-active-border-color: #71b7f1;
}

.btn-outline-primary {
  --bs-btn-color: #61afef;
  --bs-btn-border-color: #61afef;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #61afef;
  --bs-btn-hover-border-color: #61afef;
  --bs-btn-focus-shadow-rgb: 97, 175, 239;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #61afef;
  --bs-btn-active-border-color: #61afef;
}

.form-label {
  margin-bottom: .5rem;
}

.form-control {
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-clip: padding-box;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #b0d7f7;
  outline: 0;
  box-shadow: 0 0 0 .25rem #61afef40;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.d-flex {
  display: flex !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.w-100 {
  width: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.text-end {
  text-align: right !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.visible {
  visibility: visible !important;
}

@media (width >= 576px) {
  .flex-sm-column {
    flex-direction: column !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }
}

@media (width >= 1200px) {
  .fs-3 {
    font-size: 1.75rem !important;
  }
}

.upper-row {
  flex-flow: column;
  height: 45vh;
  -webkit-mask: linear-gradient(to top, #000 0 87%, #0000 92% 0) 100% / 100% 100% repeat-x;
  mask: linear-gradient(to top, #000 0 87%, #0000 92% 0) 100% / 100% 100% repeat-x;
}

.upper-row > :first-child {
  margin-top: auto;
}

.p-6 {
  padding: .75rem;
}

.min-width-0 {
  min-width: 0;
}

/*# sourceMappingURL=index.0bde399a.css.map */
