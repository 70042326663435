@use "_modules/variables" as *;

.upper-row {
  flex-flow: column nowrap;
  height: 45vh;
  mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 0,
      rgba(0, 0, 0, 1) 87%,
      rgba(0, 0, 0, 0) 92%,
      rgba(0, 0, 0, 0) 0
    )
    100% 50% / 100% 100% repeat-x;

  > :first-child {
    margin-top: auto;
  }
}

.p-6 {
  padding: ($spacer * 0.75);
}

.min-width-0 {
  min-width: 0;
}
